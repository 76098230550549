<template>
  <div class="appbar">
    <el-container>
      <el-aside style="width: 200px">
        <div class="logo" :class="isCollapse ? 'minLogo' : ''"></div>
        <el-menu
          background-color="#222"
          text-color="#eee"
          :default-active="active || this.$route.path"
          class="el-menu-vertical"
          router
          :collapse="isCollapse"
        >
          <el-submenu
            v-for="item in navAll"
            :key="item.navItem"
            :hidden="!item.subMenu"
          >
            <template slot="title" :v-if="item.subMenu">
              <i :class="item.icon"></i>
              <span slot="title">
                {{ item.navItem }}
              </span>
            </template>
            <el-menu-item
              :index="item2.path"
              v-for="item2 in item.children"
              :key="item2.path"
              class="children_menu"
              >{{ item2.navItem }}</el-menu-item
            >
          </el-submenu>
          <el-menu-item
            v-for="item in navAll"
            :index="item.path"
            :key="item.path"
            :hidden="item.subMenu"
            :class="!item.children ? 'no_children1' : ''"
            ><i :class="'iconfont ' + item.icon"></i>{{ item.navItem }}</el-menu-item
          >
        </el-menu>
      </el-aside>
      <el-container>
        <el-main>
          <slot></slot>
        </el-main>
        <slot name="footer"></slot>
      </el-container>
    </el-container>
    <div class="login_out" @click="exitWin">
      <i class="iconfont icon-a-ziyuan1"></i>
      退出登录
    </div>
  </div>
</template>
<style lang="less" scoped>
@deep: ~">>>";
.tuichuimg {
  width: 16px;
  height: 16px;
  margin-top: 25px;
}
.box {
  position: relative;
}
.iconfont,
.el-icon-s-tools {
  color: #fff !important;
  margin-right: 9px;
}
.iconfont {
  margin-left: 4px;
}
.el-icon-s-tools {
  margin-right: 0;
}
.icon-ziliaoguanli {
  font-size: 15px;
}
.login_out {
  position: absolute;
  bottom: 20px;
  left: 38px;
  font-size: 14px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  height: 35px;
  line-height: 35px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  i {
    margin-right: 8px;
    font-size: 14px;
  }
}
.children_menu {
  position: relative;
  padding-left: 50px !important;
  &::before {
    position: absolute;
    left: 32px;
    content: '';
    height: 100%;
    width: 1px;
    background-color: #fff;
  }
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 5px;
    display: none;
    content: "";
    background-color: red;
  }
  &:hover {
    background-color: #1675CA !important;
    &::after {
      display: block;
    }
  }
}
.children_menu.is-active {
  background-color: #1675CA !important;
  &::after {
    display: block;
  }

}
/deep/ .el-submenu .el-submenu__title,
.el-menu-item {
  background-color: #0863B5 !important;
  color: #fff !important;
  font-size: 15px;
  .el-submenu__icon-arrow {
    color: #fff;
    font-size: 15px;
  }
}
.red {
  width: 10px;
  height: 10px;
  background: rgb(151, 19, 19);
  position: absolute;
  right: 45px;
  bottom: 59%;
  border-radius: 50%;
  font-size: 13px;
  transform: translateY(5px);
}
.wenti {
  display: flex;
  align-items: center;
  span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid rgb(173, 173, 173);
    line-height: 16px;
    text-align: center;
    font-weight: 700;
    margin-top: -8px;
  }
}
.appbar {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  & > .el-container {
    height: 100%;
  }
  .el-aside {
    position: relative;
    background-color: #0863B5;
    overflow: visible;
    .packUp {
      position: absolute;
      right: 0;
      top: 40%;
      padding: 9px 0;
      color: #eee;
      font-size: 14px;
      font-weight: 700;
      background-color: #696c70;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      transition: all 0.5s;
      transform: translateX(0) rotateY(0);
      &.unfold {
        transform: translateX(100%) rotateY(180deg);
      }
    }
    .logo {
      width: 140px;
      margin: 30px auto;
      height: 140px;
      border-radius: 50%;
      overflow: hidden;
      background: url("../assets/img/logo.png") no-repeat center/120% auto #fff;
      transition: width 0.5s;
      &.minLogo {
        width: 80%;
      }
    }
    .el-menu {
      border: none;
    }
    .el-menu-vertical:not(.el-menu--collapse) {
      width: 200px;
      //   min-height: 400px;
    }
  }

  .el-header {
    background-color: #fff;
    color: #333;
    line-height: 80px;
    display: flex;
    justify-content: space-between;
    @{deep}.nav-left {
      // float: left;
      display: flex;
      & > div {
        width: 116px;
        margin-right: 24px;
        .el-input {
          input {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid #acacac;
          }
        }
        .el-button {
          padding: 0;
          height: 32px;
          width: 100%;
          border-color: #696c70;
        }
        &:nth-child(1) {
          width: 130px;
        }
        &:nth-child(2),
        &:nth-child(4) {
          .el-button {
            background: none;
            color: #696c70;
          }
        }
        &:nth-child(3) {
          .el-button {
            background-color: #696c70;
            color: #fff;
          }
        }
      }
      // height: 44px;
    }
    .nav-right {
      display: flex;
      li {
        margin-left: 20px;
        color: #fff;
        .iconfont,
        i {
          font-size: 20px;
          font-weight: 700;
          color: #fff;
        }
        span {
          &.identityBg {
            padding: 4px 10px 1px 6px;
            display: inline-block;
            // width: 32px;
            height: 18px;
            background: url("../assets/img/p1_1.png") no-repeat top/100% 100%;
            line-height: 1;
            font-size: 12px;
            color: #fff;
            transform: translateY(-65%);
          }
          &:nth-child(2) {
            margin: 0 3px;
          }
        }
        &:nth-child(2) {
          position: relative;
          p {
            position: absolute;
            right: -8px;
            top: 20px;
            width: 18px;
            height: 18px;
            border-radius: 100%;
            background-color: #ff4b4b;
            line-height: 18px;
            font-size: 12px;
            color: #fff;
            text-align: center;
          }
        }
      }
    }
  }
  .el-main {
    overflow-y: auto;
    // padding: 0;
  }
}
</style>

<script>
export default {
  props: { active: String },
  data() {
    return {
      num: 1,
      search: "",
      isCollapse: false,
      navList: [],
      z_page: 0,
      z_red: false,
      navAll: [
        {
          path: "",
          navItem: "系统管理",
          icon: "el-icon-s-tools",
          subMenu: true,
          children: [
            { navItem: "员工管理", path: "/staff" },
            { navItem: "角色管理", path: "/role" },
            { navItem: "组织管理", path: "/organization" }
          ]
        },
        {
          path: "/Index",
          navItem: "竞争站地图",
          icon: "icon-ditu",
          subMenu: false
        },
        {
          path: "/order-list",
          navItem: "订单列表",
          icon: "icon-ziliaoguanli",
          subMenu: false
        }
        // {
        //   path: "",
        //   navItem: "提示信息管理",
        //   icon: "el-icon-school",
        //   subMenu: true,
        //   children: [
        //     { navItem: "员工管理", path: "" },
        //     { navItem: "角色管理", path: "" },
        //     { navItem: "组织管理", path: "" },
        //     { navItem: "业主管理", path: "" },
        //     { navItem: "营运区域管理", path: "" }
        //   ]
        // },
        // {
        //   path: "",
        //   navItem: "资料管理",
        //   icon: "el-icon-school",
        //   subMenu: true,
        //   children: [
        //     { navItem: "员工管理", path: "" },
        //     { navItem: "角色管理", path: "" },
        //     { navItem: "组织管理", path: "" },
        //     { navItem: "业主管理", path: "" },
        //     { navItem: "营运区域管理", path: "" }
        //   ]
        // },
        // {
        //   path: "",
        //   navItem: "价格维护",
        //   icon: "el-icon-school",
        //   subMenu: true,
        //   children: [
        //     { navItem: "员工管理", path: "" },
        //     { navItem: "角色管理", path: "" },
        //     { navItem: "组织管理", path: "" },
        //     { navItem: "业主管理", path: "" },
        //     { navItem: "营运区域管理", path: "" }
        //   ]
        // },
        // {
        //   path: "",
        //   navItem: "油品订货",
        //   icon: "el-icon-school",
        //   subMenu: true,
        //   children: [
        //     { navItem: "员工管理", path: "" },
        //     { navItem: "角色管理", path: "" },
        //     { navItem: "组织管理", path: "" },
        //     { navItem: "业主管理", path: "" },
        //     { navItem: "营运区域管理", path: "" }
        //   ]
        // },
        // {
        //   path: "",
        //   navItem: "模板管理",
        //   icon: "el-icon-school",
        //   subMenu: true,
        //   children: [
        //     { navItem: "员工管理", path: "" },
        //     { navItem: "角色管理", path: "" },
        //     { navItem: "组织管理", path: "" },
        //     { navItem: "业主管理", path: "" },
        //     { navItem: "营运区域管理", path: "" }
        //   ]
        // },
        // {
        //   path: "",
        //   navItem: "公告管理",
        //   icon: "el-icon-school",
        //   subMenu: true,
        //   children: [
        //     { navItem: "员工管理", path: "" },
        //     { navItem: "角色管理", path: "" },
        //     { navItem: "组织管理", path: "" },
        //     { navItem: "业主管理", path: "" },
        //     { navItem: "营运区域管理", path: "" }
        //   ]
        // },
        // {
        //   path: "",
        //   navItem: "巡检管理",
        //   icon: "el-icon-school",
        //   subMenu: true,
        //   children: [
        //     { navItem: "员工管理", path: "" },
        //     { navItem: "角色管理", path: "" },
        //     { navItem: "组织管理", path: "" },
        //     { navItem: "业主管理", path: "" },
        //     { navItem: "营运区域管理", path: "" }
        //   ]
        // },
        // {
        //   path: "",
        //   navItem: "保修管理",
        //   icon: "el-icon-school",
        //   subMenu: true,
        //   children: [
        //     { navItem: "员工管理", path: "" },
        //     { navItem: "角色管理", path: "" },
        //     { navItem: "组织管理", path: "" },
        //     { navItem: "业主管理", path: "" },
        //     { navItem: "营运区域管理", path: "" }
        //   ]
        // },
        // {
        //   path: "",
        //   navItem: "拓站管理",
        //   icon: "el-icon-school",
        //   subMenu: true,
        //   children: [
        //     { navItem: "员工管理", path: "" },
        //     { navItem: "角色管理", path: "" },
        //     { navItem: "组织管理", path: "" },
        //     { navItem: "业主管理", path: "" },
        //     { navItem: "营运区域管理", path: "" }
        //   ]
        // },
        // {
        //   path: "",
        //   navItem: "签掉管理",
        //   icon: "el-icon-school",
        //   subMenu: true,
        //   children: [
        //     { navItem: "员工管理", path: "" },
        //     { navItem: "角色管理", path: "" },
        //     { navItem: "组织管理", path: "" },
        //     { navItem: "业主管理", path: "" },
        //     { navItem: "营运区域管理", path: "" }
        //   ]
        // },
        // {
        //   path: "",
        //   navItem: "油站地图管理",
        //   icon: "el-icon-school",
        //   subMenu: false
        // },
        // { path: "", navItem: "报表", icon: "el-icon-school", subMenu: false }
      ]
    };
  },
  methods: {
    exitWin() {
      this.$confirm("确定退出该账号?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          sessionStorage.setItem("token", "");
          sessionStorage.setItem("id", "");
          localStorage.setItem("user", "");
          localStorage.setItem("pass", "");
          this.$router.replace({ path: "/" });
        })
        .catch(() => {});
    }
  },
  computed: {
    getRole_id() {
      return sessionStorage.getItem("role_id") * 1;
    }
  },
  created() {
    const userCode = JSON.parse(sessionStorage.getItem("loginMsg"));
    if(userCode && userCode.user_code && (this.codeList.indexOf(userCode.user_code) > -1 || userCode.user_code == 'managertmh')) {
      this.navAll = [
        {
          path: "/order-list",
          navItem: "订单列表",
          icon: "el-icon-school",
          subMenu: false
        }
      ];
    }
  }
};
// 提醒弹出框
function setmas(msg, type, vm) {
  vm.$message({
    message: msg,
    type: type,
    customClass: "popup"
  });
}
</script>
<style lang="less">
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top_box {
  background: #fff;
  padding: 20px 0;
  border-radius: 10px;
  margin-bottom: 20px;
}
.body_box {
  background: #fff;
  padding: 20px 0;
  border-radius: 10px;
  .mb8 {
    padding-left: 20px;
  }
}
.popup {
  width: 400px;
  height: 70px;
  font-size: 26px;
  font-weight: 700;
  position: absolute !important;
  top: 45% !important;
  margin-top: -35px !important;
}
.el-aside {
  /* width: 200px !important; */
}
.el-submenu .el-menu-item {
  min-width: auto;
}
.no_children .el-icon-arrow-down {
  display: none;
}
.el-menu-item {
  padding-left: 40px !important;
}
.no_children1 {
  padding-left: 20px !important;
}
</style>
